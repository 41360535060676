/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx, Box } from 'theme-ui';
import Slider from 'react-slick';
import TextBlock from '../TextBlock';
import { ThemeHeader, TheRayLetterHorizontalLinesLogo, TheRayLetterLogo } from '../../components';
import HeroImage from '../HeroImage';
import CTALink from '../CTALink';
import heroPattern from '../../images/hero-pattern.png';
import useElementOnScreen from '../../hooks/useElementOnScreen';
import { AppControlContext } from '../../context/appControl.context';

const Hero = ({ pageBody, ...props }) => {
  const {
    _rawCta: cta,
    _rawText: text,
    _rawCarouselDayImages: carouselDayImages,
    _rawCarouselNightImages: carouselNightImages,
    bgColor,
    title,
    style,
    mode,
    showTheRayLogoVectorIcon,
    showTheRayLogoHorizontalLines,
    chooseVerticalLinesPlacement,
  } = pageBody;

  let isHomeVideo = false;// (title == 'Home-Hero-Video') || (title == 'Home-Hero');
  let isFitnessVideo = (title == 'Health & Wellness Hero');
  let isMeetingsAndEvents = (title == 'Event 1');

  const [hour, setHour] = useState(new Date().getHours());
  const { setHeroImageTransComplete } = React.useContext(AppControlContext);

  let slider;
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
  const [sliderSettings, setSliderSettings] = React.useState({
    fade: true,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 800,
    arrows: false,
    dots: false,
    draggable: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    touchMove: false,
    beforeChange: (currentIdx, NextIndx) => {
      setActiveSlideIndex(NextIndx);
    },
  });
  useEffect(() => {
    setHour(new Date().getHours());
  });

  useEffect(() => {
    const HeroImageElement = document.querySelector('#HeroImageBox');
    HeroImageElement.addEventListener(
      'transitionend',
      (e) => {
        if (e.pseudoElement === '::before') {
          setHeroImageTransComplete(true);
        }
      },
      false
    );
  }, []);

  // Animation refrences, Animate when element visible on screen
  const [HeroImgRef, isHeroImgVisible] = useElementOnScreen();
  const [HeroLogoRef, isHeroLogoVisible] = useElementOnScreen();
  const [HeroHeadlineRef, isHeroHeadlineVisible] = useElementOnScreen();
  const [HeroTextRef, isHeroTextVisible] = useElementOnScreen();
  const [HeroCTARef, isHeroCTAVisible] = useElementOnScreen();
  const [HeroVerticalLineTopRef, isHeroVerticalLineTopVisible] = useElementOnScreen();
  const [HeroVerticalLineBottomRef, isHeroVerticalLineBottomVisible] = useElementOnScreen();
  const [HeroPatternRef, isHeroPatternVisible] = useElementOnScreen();

  const getLayoutStyle = (value) => {
    switch (value) {
      case '1':
        return HeroStyle1;
      case '2':
        return HeroStyle2;
      case '3':
        return HeroStyle3;
      case '4':
        return HeroStyle4;
      default:
        return HeroStyle3;
    }
  };

  let videoHTML = '<script>let headervolume = 0;function toggleHeaderMute() {let viframe = document.getElementById("videoheaderframe");let vplayer = $f(viframe);console.log("Changing volume: " + headervolume);	if (headervolume === 0) headervolume = 100;	else headervolume = 0;    vplayer.api("setVolume", headervolume);}</script><div style="width:100%;height:637px;position:relative;overflow:hidden;" onclick="toggleHeaderMute();console.log(\'click\')";><div onclick="toggleHeaderMute();console.log(\'click\')"; style="position:relative;width:100%;height:100%;min-height:calc((100vw - 40px) * 0.5625);min-width:calc((100vh + 40px) * 1.347);"><iframe id="videoheaderframe" src="https://player.vimeo.com/video/663120698?h=7eae915d94&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&amp=1&muted=1&loop=1&controls=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="The Ray_Final.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script></div>';
  // Tools for working with vimeo videos
  videoHTML = '<script>var Froogaloop=function(){function e(a){return new e.fn.init(a)}function g(a,c,b){if(!b.contentWindow.postMessage)return!1;a=JSON.stringify({method:a,value:c});b.contentWindow.postMessage(a,h)}function l(a){var c,b;try{c=JSON.parse(a.data),b=c.event||c.method}catch(e){}"ready"!=b||k||(k=!0);if(!/^https?:\/\/player.vimeo.com/.test(a.origin))return!1;"*"===h&&(h=a.origin);a=c.value;var m=c.data,f=""===f?null:c.player_id;c=f?d[f][b]:d[b];b=[];if(!c)return!1;void 0!==a&&b.push(a);m&&b.push(m);f&&b.push(f);return 0<b.length?c.apply(null,b):c.call()}function n(a,c,b){b?(d[b]||(d[b]={}),d[b][a]=c):d[a]=c}var d={},k=!1,h="*";e.fn=e.prototype={element:null,init:function(a){"string"===typeof a&&(a=document.getElementById(a));this.element=a;return this},api:function(a,c){if(!this.element||!a)return!1;var b=this.element,d=""!==b.id?b.id:null,e=c&&c.constructor&&c.call&&c.apply?null:c,f=c&&c.constructor&&c.call&&c.apply?c:null;f&&n(a,f,d);g(a,e,b);return this},addEvent:function(a,c){if(!this.element)return!1;var b=this.element,d=""!==b.id?b.id:null;n(a,c,d);"ready"!=a?g("addEventListener",a,b):"ready"==a&&k&&c.call(null,d);return this},removeEvent:function(a){if(!this.element)return!1;var c=this.element,b=""!==c.id?c.id:null;a:{if(b&&d[b]){if(!d[b][a]){b=!1;break a}d[b][a]=null}else{if(!d[a]){b=!1;break a}d[a]=null}b=!0}"ready"!=a&&b&&g("removeEventListener",a,c)}};e.fn.init.prototype=e.fn;window.addEventListener?window.addEventListener("message",l,!1):window.attachEvent("onmessage",l);return window.Froogaloop=window.$f=e}();</script>' + videoHTML;

  let homeVideoHTML = '<script>let headervolume = 0;function toggleHeaderMute() {let viframe = document.getElementById("videoheaderframe");let vplayer = $f(viframe);console.log("Changing volume: " + headervolume);	if (headervolume === 0) headervolume = 100;	else headervolume = 0;    vplayer.api("setVolume", headervolume);}</script><div style="width:100%;height:637px;position:relative;overflow:hidden;  display: flex;align-items: center;justify-content: center;}" onclick="toggleHeaderMute();console.log(\'click\')";><div onclick="toggleHeaderMute();console.log(\'click\')"; style="position:relative;width:100%;height:100%;min-height:calc((100vw - 40px) * 0.5625);min-width:calc((100vh + 40px) * 1.347);"><iframe id="videoheaderframe" src="https://player.vimeo.com/video/672922192?h=544ade3cda&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&amp=1&muted=1&loop=1&controls=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="The Ray_Final.mp4"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script></div>';
  // Tools for working with vimeo videos
  homeVideoHTML = '<script>var Froogaloop=function(){function e(a){return new e.fn.init(a)}function g(a,c,b){if(!b.contentWindow.postMessage)return!1;a=JSON.stringify({method:a,value:c});b.contentWindow.postMessage(a,h)}function l(a){var c,b;try{c=JSON.parse(a.data),b=c.event||c.method}catch(e){}"ready"!=b||k||(k=!0);if(!/^https?:\/\/player.vimeo.com/.test(a.origin))return!1;"*"===h&&(h=a.origin);a=c.value;var m=c.data,f=""===f?null:c.player_id;c=f?d[f][b]:d[b];b=[];if(!c)return!1;void 0!==a&&b.push(a);m&&b.push(m);f&&b.push(f);return 0<b.length?c.apply(null,b):c.call()}function n(a,c,b){b?(d[b]||(d[b]={}),d[b][a]=c):d[a]=c}var d={},k=!1,h="*";e.fn=e.prototype={element:null,init:function(a){"string"===typeof a&&(a=document.getElementById(a));this.element=a;return this},api:function(a,c){if(!this.element||!a)return!1;var b=this.element,d=""!==b.id?b.id:null,e=c&&c.constructor&&c.call&&c.apply?null:c,f=c&&c.constructor&&c.call&&c.apply?c:null;f&&n(a,f,d);g(a,e,b);return this},addEvent:function(a,c){if(!this.element)return!1;var b=this.element,d=""!==b.id?b.id:null;n(a,c,d);"ready"!=a?g("addEventListener",a,b):"ready"==a&&k&&c.call(null,d);return this},removeEvent:function(a){if(!this.element)return!1;var c=this.element,b=""!==c.id?c.id:null;a:{if(b&&d[b]){if(!d[b][a]){b=!1;break a}d[b][a]=null}else{if(!d[a]){b=!1;break a}d[a]=null}b=!0}"ready"!=a&&b&&g("removeEventListener",a,c)}};e.fn.init.prototype=e.fn;window.addEventListener?window.addEventListener("message",l,!1):window.attachEvent("onmessage",l);return window.Froogaloop=window.$f=e}();</script>' + homeVideoHTML;

  const HeroStyle = getLayoutStyle(style);

  const sliderMarkup = (
      <Slider
          ref={(c) => {
            if (c !== null) {
              slider = c;
            }
          }}
          {...sliderSettings}
      >
        {hour >= 6 && hour < 18
            ? carouselDayImages.map((element) => {
              return <HeroImage key={Math.random().toString(36).substring(7)} src={element.asset._ref} alt="Hero Image" />;
            })
            : carouselNightImages.map((element) => {
              return <HeroImage key={Math.random().toString(36).substring(7)} src={element.asset._ref} alt="Hero Image" />;
            })}
      </Slider>
  );

  let meetingCTA = {};
  if (isMeetingsAndEvents) {
    meetingCTA = JSON.parse(JSON.stringify(cta));
    meetingCTA.label = 'VIEW OUR OFFERINGS';
    meetingCTA.link.linkUrl = 'https://heyzine.com/flip-book/e92424eab3.html';
  }

  return (
    <Box sx={{ backgroundColor: bgColor, ...HeroWrapperStyle, ...HeroStyle.base }}>
      <Box id="HeroImageBox" ref={HeroImgRef} sx={HeroImgContainerStyle(isHeroImgVisible)}>
        <Box sx={HeroImgStyle(isHeroImgVisible)}>
          {(isFitnessVideo ?
              <Box dangerouslySetInnerHTML={{__html: videoHTML }}/>
           :  (isHomeVideo ?
                  <Box dangerouslySetInnerHTML={{__html: homeVideoHTML }}/>
                  :sliderMarkup))}
        </Box>
      </Box>
      {showTheRayLogoVectorIcon || text || cta || style === '2' ? (
        <Box sx={{ ...HeroContentStyle, ...HeroStyle.content }}>
          {chooseVerticalLinesPlacement === 'top' && (
            <Box
              ref={HeroVerticalLineTopRef}
              sx={{
                ...HeroVerticalLineStyle.base,
                ...HeroVerticalLineStyle.top,
                ...{ transition: 'height 1s ease 2.25s', height: isHeroVerticalLineTopVisible ? [64, null, 125] : 0 },
              }}
            ></Box>
          )}
          {showTheRayLogoVectorIcon && (
            <Box ref={HeroLogoRef} sx={HeroIconStyles}>
              {showTheRayLogoHorizontalLines && <TheRayLetterHorizontalLinesLogo isVisible={isHeroLogoVisible} />}
              {!showTheRayLogoHorizontalLines && (
                <Box sx={{ display: 'inline-block', transition: 'opacity 1s ease', opacity: isHeroLogoVisible ? 1 : 0 }}>
                  <TheRayLetterLogo />
                </Box>
              )}
            </Box>
          )}
          {title && style === '2' ? (
            <Box ref={HeroHeadlineRef} sx={{ ...HeroHeadlineStyles, ...{ opacity: isHeroHeadlineVisible ? 1 : 0 } }}>
              <ThemeHeader.H1 isGradient isShimmer={isHeroHeadlineVisible}>
                {title}
              </ThemeHeader.H1>
            </Box>
          ) : null}
          {text && (
            <Box ref={HeroTextRef} sx={{ transition: 'opacity 1s ease 0.75s', opacity: isHeroTextVisible ? 1 : 0 }}>
              <TextBlock styleBlock={HeroTextStyles(mode)} description={text} />
            </Box>
          )}
          {cta?.link?.showLink && (isMeetingsAndEvents ?
            <Box ref={HeroCTARef} sx={{ transition: 'opacity 1s ease 1s', opacity: isHeroCTAVisible ? 1 : 0 }}>
              <CTALink linkData={meetingCTA} />
            </Box>
                  : null )}
          {cta?.link?.showLink && (
                  <Box ref={HeroCTARef} sx={{ transition: 'opacity 1s ease 1s', opacity: isHeroCTAVisible ? 1 : 0 }}>
                    <CTALink linkData={cta} />
                  </Box>
          )}
          {chooseVerticalLinesPlacement === 'bottom' && (
            <Box
              ref={HeroVerticalLineBottomRef}
              sx={{
                ...HeroVerticalLineStyle.base,
                ...HeroVerticalLineStyle.bottom,
                ...{ transition: 'height 1s ease 1.25s', height: isHeroVerticalLineBottomVisible ? [64, null, 125] : 0 },
              }}
            />
          )}
        </Box>
      ) : null}
      {style === '3' && <Box ref={HeroPatternRef} sx={HeroBgPattern(isHeroPatternVisible)}></Box>}
    </Box>
  );
};

// Hero defaults
const HeroWrapperStyle = {
  position: 'relative',
};

const HeroImgContainerStyle = (isHeroImgVisible) => ({
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    transition: 'height 1.25s ease 1s',
    height: isHeroImgVisible ? '50%' : 0,
    backgroundColor: 'background',
  },
});

const HeroImgStyle = (isHeroImgVisible) => ({
  variant: 'styles.wrapperSpace',
  pb: (theme) => [`${theme.stylesConfig.containerSpaceMobile}`, null, `${theme.stylesConfig.containerSpaceDesktop}`],
  position: 'relative',
  transition: 'opacity 1s ease',
  opacity: isHeroImgVisible ? 1 : 0,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
    bg: 'background',
    transition: 'height 1.25s ease 1s',
    height: isHeroImgVisible ? 0 : '100%',
  },
  '.gatsby-image-wrapper': {
    transition: 'transform 1.25s ease 1s',
    transform: isHeroImgVisible ? 'scale(1)' : 'scale(2)',
  },
});

const HeroContentStyle = {
  position: 'relative',
  textAlign: 'center',
  maxWidth: 640,
  mx: 'auto',
  px: 4,
};

const HeroIconStyles = {
  pb: [4, null, 7],
};

const HeroHeadlineStyles = {
  transition: 'opacity 1s ease 0.5s',
  maxWidth: ['80%', null, 512],
  textAlign: 'center',
  color: 'text',
  mx: 'auto',
  pt: [null, null, 4],
};

const HeroTextStyles = (mode) => ({
  color: mode ? 'textInverted' : 'text',
});

const HeroVerticalLineStyle = {
  base: {
    variant: 'styles.goldLine',
    width: 2,
    left: 0,
    right: 0,
    mx: 'auto',
    position: 'absolute',
  },
  top: {
    top: (theme) => [`-${theme.stylesConfig.containerSpaceMobile}`, null, `-${theme.stylesConfig.containerSpaceDesktop}`],
    transform: 'translateY(-50%)',
  },
  bottom: {
    bottom: 0,
    transform: 'translateY(50%)',
  },
};

const HeroBgPattern = (isHeroPatternVisible) => ({
  position: 'absolute',
  backgroundImage: `url(${heroPattern})`,
  width: '100%',
  bottom: 0,
  left: 0,
  height: 105,
  transition: ' background-position 1s ease 1s',
  backgroundPosition: isHeroPatternVisible ? '0 0' : '0 105px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

// Hero variant styles
const HeroStyle1 = {
  content: {
    pb: 8,
    pt: [5, null, 9],
  },
};

const HeroStyle2 = {
  content: {
    py: 8,
  },
};

const HeroStyle3 = {
  content: {
    py: 10,
    pb: 200,
  },
};

const HeroStyle4 = {
  content: {
    py: 10,
    mb: [8, null, 5],
  },
};
export default Hero;
